import React from 'react';
import clsx from 'clsx';
import {Link as GatsbyLink} from 'gatsby';
import tintashLogoSrc from '../../../assets/images/connect/tintash-logo.webp';

import {VARIANT_A_KEY, VARIANT_B_KEY} from '../Constants';

export const CONTACT_US_PAPERFROM_ID = 'h6h2thbf';

function HeadingSection({subtitles, title, variant}) {
  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://paperform.co/__embed.min.js';
    document.body.appendChild(script);
  }, []);

  return (
    <section
      className={clsx({
        'connect__heading-section-a': variant === 'A',
        'connect__heading-section': variant === 'B',
      })}
    >
      <div className="container-fluid connect__landing-container-fluid">
        <div className="row box1">
          <div className="col-12 col-md-7 box2">
            <GatsbyLink to="/" className="connect__heading-link">
              <img src={tintashLogoSrc} alt="Tintash Logo" />
            </GatsbyLink>
            <h1 className="connect__heading-subtitle">{title}</h1>

            {variant === 'B' && (
              <div className="connect__heading-title">
                Partner with Us and{' '}
                <span className="connect__heading-title--bold">Supercharge</span> Your{' '}
                <span className="connect__heading-title--bold">Growth</span>
              </div>
            )}
            {variant === 'A' && (
              <h2 className="connect__heading-title connect__heading-title--b">
                We <span className="connect__heading-title--bold">Design</span> &amp;
                <br />
                <span className="connect__heading-title--bold">Build Products</span>
              </h2>
            )}

            <p className="connect__heading-description">
              {variant === 'A' && subtitles[VARIANT_A_KEY]}
              {variant === 'B' && subtitles[VARIANT_B_KEY]}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeadingSection;
